<template>
  <div>
    <data-tables
      :data="data ? data : []"
      :pagination-props="paginationDef"
      :table-props="tableProps"
      :show-action-bar="false"
      style="width: 100%"
      class="tracker-history-table"
    >
      <el-table-column label="Status" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'success'">
            <font-awesome-icon
              icon="check"
              fixed-width
              class="success"
            ></font-awesome-icon>
          </span>
          <span v-if="scope.row.status === 'error'">
            <font-awesome-icon
              icon="times"
              fixed-width
              class="danger"
            ></font-awesome-icon>
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Type" width="100">
        <template slot-scope="scope">
          {{ scope.row.type.replace(/^\w/, (c) => c.toUpperCase()) }}
        </template>
      </el-table-column>

      <el-table-column label="Service" width="150">
        <template slot-scope="scope">
          {{ scope.row.service }}
        </template>
      </el-table-column>

      <el-table-column prop="updated_at" label="Timestamp" width="150" sortable>
        <template slot-scope="scope">
          {{ dateTimeFormat(scope.row.updated_at, "MM/DD/YY hh:mm a") }}
        </template>
      </el-table-column>

      <el-table-column label="Message" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.message }}
        </template>
      </el-table-column>
    </data-tables>
    <p class="text-medium" style="margin-top: 0.5rem; text-align: center">
      Only the most recent successful updates are shown. All non-updates and
      errors are shown.
    </p>
  </div>
</template>

<style scoped>
</style>

<script>
import DateTime from "@/mixins/DateTime"

export default {
  name: "TrackerHistoryTable",

  mixins: [DateTime],
  components: {},

  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 10,
        pageSizes: [10],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "updated_at",
          order: "descending",
        },
        highlightCurrentRow: false,
      },
    }
  },
  computed: {},
  watch: {},

  methods: {},

  created() {},
  mounted() {},
}
</script>
