<template>
  <el-card>
    <h5>Update History</h5>
    <tracker-history-table :data="data"></tracker-history-table>
  </el-card>
</template>

<style scoped>
</style>

<script>
import TrackerHistoryTable from "@/components/Tables/TrackerHistoryTable"

export default {
  name: "TrackerCompanyHistory",

  mixins: [],
  components: {
    TrackerHistoryTable,
  },

  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},

  methods: {},

  created() {},
  mounted() {},
}
</script>
