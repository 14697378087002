var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('el-card',[_c('el-row',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-bottom":"0.5rem"}},[_c('el-button',{attrs:{"size":"small","type":_vm.data.paused ? 'success' : 'warning'},on:{"click":_vm.pauseTracker}},[_c('font-awesome-icon',{staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":_vm.data.paused ? 'play' : 'pause',"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.data.paused ? "Activate" : "Pause")+" Tracker ")],1),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":_vm.openTrackerDeleteDataModal}},[_vm._v("Delete Data")]),_c('el-button',{attrs:{"size":"small","type":"default"},on:{"click":_vm.openTrackerEditModal}},[_vm._v("Modify")])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"service-list",attrs:{"md":16}},[_c('div',{staticClass:"tracker-settings-service",class:{
          inactive:
            _vm.availableServices.indexOf('google-display') === -1 &&
            _vm.availableServices.indexOf('facebook-display') === -1,
        }},[_c('div',{staticClass:"modify"},[(
              'google-display' in _vm.data.services ||
              'facebook-display' in _vm.data.services
            )?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('display')}}}):_vm._e()],1),_c('div',{staticClass:"status"}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_vm._v("Display")])])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('google-display') === -1,
        }},[_c('div',{staticClass:"modify"},[('google-display' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('google-display')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('google-display') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('google-display')}}),_vm._v(" Google ")]),(_vm.availableServices.indexOf('google-display') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["google-display"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["google-display"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('facebook-display') === -1,
        }},[_c('div',{staticClass:"modify"},[('facebook-display' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('facebook-display')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('facebook-display') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('facebook-display')}}),_vm._v(" Facebook ")]),(_vm.availableServices.indexOf('facebook-display') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["facebook-display"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["facebook-display"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('agility-display') === -1,
        }},[_c('div',{staticClass:"modify"},[('agility-display' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('agility-display')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('agility-display') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('agility-display')}}),_vm._v(" Agility Ads ")]),(_vm.availableServices.indexOf('agility-display') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["agility-display"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["agility-display"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('simplifi-display') === -1,
        }},[_c('div',{staticClass:"modify"},[('simplifi-display' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('simplifi-display')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('simplifi-display') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('simplifi-display')}}),_vm._v(" Simpli.fi ")]),(_vm.availableServices.indexOf('simplifi-display') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["simplifi-display"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["simplifi-display"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive:
            _vm.availableServices.indexOf('google-sem') === -1 &&
            _vm.availableServices.indexOf('bing-sem') === -1,
        }},[_c('div',{staticClass:"modify"},[(
              'google-sem' in _vm.data.services || 'bing-sem' in _vm.data.services
            )?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('sem')}}}):_vm._e()],1),_c('div',{staticClass:"status"}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_vm._v("SEM")])])]),_c('div',{staticClass:"tracker-settings-service",class:{ inactive: _vm.availableServices.indexOf('google-sem') === -1 }},[_c('div',{staticClass:"modify"},[('google-sem' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('google-sem')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('google-sem') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('google-sem')}}),_vm._v(" Google ")]),(_vm.availableServices.indexOf('google-sem') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["google-sem"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["google-sem"].end, "M/D/YY h a"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{ inactive: _vm.availableServices.indexOf('bing-sem') === -1 }},[_c('div',{staticClass:"modify"},[('bing-sem' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('bing-sem')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('bing-sem') !== -1 ? 'check' : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('bing-sem')}}),_vm._v(" Bing ")]),(_vm.availableServices.indexOf('bing-sem') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["bing-sem"].start, "M/D/YY h a"))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["bing-sem"].end, "M/D/YY h a"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive:
            _vm.availableServices.indexOf('siteimpact-mail') === -1 &&
            _vm.availableServices.indexOf('mailchimp-mail') === -1 &&
            _vm.availableServices.indexOf('emailresultz-mail') === -1 &&
            _vm.availableServices.indexOf('constantcontact-mail') === -1,
        }},[_c('div',{staticClass:"modify"},[(
              'siteimpact-mail' in _vm.data.services ||
              'mailchimp-mail' in _vm.data.services
            )?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('mail')}}}):_vm._e()],1),_c('div',{staticClass:"status"}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_vm._v("Mail")])])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('siteimpact-mail') === -1,
        }},[_c('div',{staticClass:"modify"},[('siteimpact-mail' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('siteimpact-mail')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('siteimpact-mail') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('siteimpact-mail')}}),_vm._v(" Siteimpact ")]),(_vm.availableServices.indexOf('siteimpact-mail') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["siteimpact-mail"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["siteimpact-mail"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('mailchimp-mail') === -1,
        }},[_c('div',{staticClass:"modify"},[('mailchimp-mail' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('mailchimp-mail')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('mailchimp-mail') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('mailchimp-mail')}}),_vm._v(" MailChimp ")]),(_vm.availableServices.indexOf('mailchimp-mail') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["mailchimp-mail"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["mailchimp-mail"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('emailresultz-mail') === -1,
        }},[_c('div',{staticClass:"modify"},[('emailresultz-mail' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('emailresultz-mail')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('emailresultz-mail') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('emailresultz-mail')}}),_vm._v(" Email Resultz ")]),(_vm.availableServices.indexOf('emailresultz-mail') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["emailresultz-mail"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["emailresultz-mail"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('constantcontact-mail') === -1,
        }},[_c('div',{staticClass:"modify"},[('constantcontact-mail' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('constantcontact-mail')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('constantcontact-mail') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('constantcontact-mail')}}),_vm._v(" Constant Contact ")]),(_vm.availableServices.indexOf('constantcontact-mail') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["constantcontact-mail"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["constantcontact-mail"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive:
            _vm.availableServices.indexOf('simplifi-tv') === -1 ||
            _vm.availableServices.indexOf('tremor-tv') === -1 ||
            _vm.availableServices.indexOf('settop-tv') === -1,
        }},[_c('div',{staticClass:"modify"},[(
              'simplifi-tv' in _vm.data.services ||
              'tremor-tv' in _vm.data.services ||
              'settop-tv' in _vm.data.services
            )?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('tv')}}}):_vm._e()],1),_c('div',{staticClass:"status"}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_vm._v("TV")])])]),_c('div',{staticClass:"tracker-settings-service",class:{ inactive: _vm.availableServices.indexOf('simplifi-tv') === -1 }},[_c('div',{staticClass:"modify"},[('simplifi-tv' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('simplifi-tv')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('simplifi-tv') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('simplifi-tv')}}),_vm._v(" Simplifi ")]),(_vm.availableServices.indexOf('simplifi-tv') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["simplifi-tv"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["simplifi-tv"].end, "M/D/YY h a"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{ inactive: _vm.availableServices.indexOf('agility-tv') === -1 }},[_c('div',{staticClass:"modify"},[('agility-tv' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('agility-tv')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('agility-tv') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('agility-tv')}}),_vm._v(" Agility Ads ")]),(_vm.availableServices.indexOf('agility-tv') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["agility-tv"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["agility-tv"].end, "M/D/YY h a"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{ inactive: _vm.availableServices.indexOf('tremor-tv') === -1 }},[_c('div',{staticClass:"modify"},[('tremor-tv' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('tremor-tv')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('tremor-tv') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('tremor-tv')}}),_vm._v(" Tremor ")]),(_vm.availableServices.indexOf('tremor-tv') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["tremor-tv"].start, "M/D/YY h a"))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["tremor-tv"].end, "M/D/YY h a"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{ inactive: _vm.availableServices.indexOf('settop-tv') === -1 }},[_c('div',{staticClass:"modify"},[('settop-tv' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('settop-tv')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('settop-tv') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('settop-tv')}}),_vm._v(" Set Top ")]),(_vm.availableServices.indexOf('settop-tv') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["settop-tv"].start, "M/D/YY h a"))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.data.services["settop-tv"].end, "M/D/YY h a"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive:
            _vm.availableServices.indexOf('google-video') === -1 ||
            _vm.availableServices.indexOf('facebook-video') === -1,
        }},[_c('div',{staticClass:"modify"},[(
              'google-video' in _vm.data.services ||
              'facebook-video' in _vm.data.services
            )?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('video')}}}):_vm._e()],1),_c('div',{staticClass:"status"}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_vm._v("Video")])])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('google-video') === -1,
        }},[_c('div',{staticClass:"modify"},[('google-video' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('google-video')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('google-video') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('google-video')}}),_vm._v(" Google ")]),(_vm.availableServices.indexOf('google-video') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["google-video"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["google-video"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])]),_c('div',{staticClass:"tracker-settings-service",class:{
          inactive: _vm.availableServices.indexOf('facebook-video') === -1,
        }},[_c('div',{staticClass:"modify"},[('facebook-video' in _vm.data.services)?_c('font-awesome-icon',{staticClass:"modify-service-icon",attrs:{"icon":"pencil-alt","fixed-width":""},on:{"click":function($event){return _vm.openServiceEditModal('facebook-video')}}}):_vm._e()],1),_c('div',{staticClass:"status"},[_c('font-awesome-icon',{staticClass:"status-icon",attrs:{"icon":_vm.availableServices.indexOf('facebook-video') !== -1
                ? 'check'
                : 'times',"fixed-width":""}})],1),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"service"},[_c('img',{staticClass:"service-logo",attrs:{"src":_vm.getServiceLogo('facebook-video')}}),_vm._v(" Facebook ")]),(_vm.availableServices.indexOf('facebook-video') !== -1)?_c('div',{staticClass:"date-range"},[_c('div',{staticClass:"start"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["facebook-video"].start, "M/D/YY h a" ))+" ")]),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('div',{staticClass:"end"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat( _vm.data.services["facebook-video"].end, "M/D/YY h a" ))+" ")])]):_vm._e()])])]),_c('el-col',{attrs:{"md":8}},[_c('div',{staticClass:"tracker-setting-option",class:{ inactive: !_vm.data.remarketing }},[_c('font-awesome-icon',{attrs:{"icon":_vm.data.remarketing ? 'check' : 'times',"fixed-width":""}}),_vm._v("Separate Remarketing ")],1),_c('div',{staticClass:"tracker-setting-option",class:{ inactive: !_vm.data.conversions }},[_c('font-awesome-icon',{attrs:{"icon":_vm.data.conversions ? 'check' : 'times',"fixed-width":""}}),_vm._v("Display Conversions ")],1),(_vm.data.conversions_label)?_c('div',{staticClass:"tracker-setting-option conversion-label"},[_vm._v(" Labeled as \""+_vm._s(_vm.data.conversions_label)+"\" ("+_vm._s(_vm.data.conversions_abbreviation)+".) ")]):_vm._e()])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }