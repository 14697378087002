<template>
  <el-card>
    <h5>Campaigns</h5>
    <tracker-campaign-table
      :company="company"
      :campaigns="campaigns"
    ></tracker-campaign-table>
  </el-card>
</template>

<style scoped>
</style>

<script>
import TrackerCampaignTable from "@/components/Tables/TrackerCampaignTable"

export default {
  name: "TrackerCompanyCampaigns",

  mixins: [],
  components: {
    TrackerCampaignTable,
  },

  props: {
    campaigns: {
      required: true,
    },
    company: {
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},

  methods: {},

  created() {},
  mounted() {},
}
</script>
