<template>
  <el-form
    @submit.native.prevent="update"
    :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
    label-width="150px"
  >
    <el-form-item label="Settings">
      <el-checkbox v-model="tracker.remarketing">
        Separate Remarketing
      </el-checkbox>
      <el-checkbox v-model="tracker.conversions">
        Show Conversions
      </el-checkbox>
    </el-form-item>

    <el-form-item
      :error="
        form.errors.first('conversions_label')
          ? form.errors.first('conversions_label')
          : form.errors.first('conversions_abbreviation')
          ? form.errors.first('conversions_abbreviation')
          : null
      "
      label="Conversions Label"
      v-if="tracker.conversions"
    >
      <el-row>
        <el-col :span="18" style="padding-right: 0.5rem">
          <el-input
            v-model="tracker.conversions_label"
            type="text"
            placeholder="Full"
            @focus="form.errors.clear('conversions_label')"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="tracker.conversions_abbreviation"
            type="text"
            placeholder="Abbrv."
            @focus="form.errors.clear('conversions_abbreviation')"
            maxlength="4"
          ></el-input>
        </el-col>
      </el-row>
    </el-form-item>

    <el-button
      type="primary"
      plain
      medium
      native-type="submit"
      v-loading="loading.form"
      :style="{ width: '100%' }"
    >
      Create
    </el-button>
  </el-form>
</template>

<style lang="scss">
</style>

<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "TrackerCreateForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      tracker: {
        remarketing: true,
        conversions: false,
        conversions_label: null,
        conversions_abbreviation: null,
      },
      form: new Form(),
      loading: {
        form: false,
      },
    }
  },
  computed: {},

  methods: {
    ...mapActions({
      updateAuth: "auth/update",
      updateCompany: "tracker/updateAdmin",
    }),
    update() {
      this.loading.form = true

      let formData = {
        company: this.existing.slug,
        remarketing: this.tracker.remarketing,
        conversions: this.tracker.conversions,
      }

      if (formData.conversions) {
        formData.conversions_label = this.tracker.conversions_label
        formData.conversions_abbreviation =
          this.tracker.conversions_abbreviation
      } else {
        formData.conversions_label = formData.conversions_abbreviation = null
      }

      this.axios
        .post("tracker/create/", formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.updateAuth()
          this.$message.success(response.data.message)
          this.loading.form = false
        })
        .catch((resp) => {
          this.loading.form = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {},
}
</script>
