<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix modal-heading">
        <span>Manual Data from Template Upload</span>
        <el-button type="text" @click="$modal.hide('tracker-template-form')">
          <font-awesome-icon
            icon="window-close"
            fixed-width
          ></font-awesome-icon>
        </el-button>
      </div>

      <div>
        <el-form
          @submit.native.prevent="submit"
          :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
          label-width="100px"
        >
          <el-form-item
            :error="
              form.errors.first('template')
                ? form.errors.first('template')
                : null
            "
            label="Template"
          >
            <modal-select>
              <el-select
                v-model="data.template"
                :style="{ width: '100%' }"
                @focus="form.errors.clear('template')"
              >
                <el-option
                  v-for="template in tracker.worksheet_templates"
                  :key="template.id"
                  :label="template.name"
                  :value="template.id"
                ></el-option>
              </el-select>
            </modal-select>
          </el-form-item>

          <el-form-item
            :error="
              form.errors.first('type') ? form.errors.first('type') : null
            "
            label="File Type"
          >
            <modal-select>
              <el-select
                v-model="data.type"
                :style="{ width: '100%' }"
                @focus="form.errors.clear('type')"
              >
                <el-option label="Excel" value="Xlsx"></el-option>
                <el-option label="Excel (xls)" value="Xls"></el-option>
                <el-option label="CSV" value="Csv"></el-option>
              </el-select>
            </modal-select>
          </el-form-item>

          <el-form-item
            :error="
              form.errors.first('file') ? form.errors.first('file') : null
            "
            label="File"
          >
            <el-upload
              :http-request="processFile"
              action="#"
              ref="upload"
              :show-file-list="false"
            >
              <el-button slot="trigger" type="primary">{{
                data.file ? data.file.name : "Select a File"
              }}</el-button>
              <el-button v-if="data.file" @click="data.file = null">
                <font-awesome-icon
                  icon="times"
                  size="small"
                  full-width
                ></font-awesome-icon>
              </el-button>
            </el-upload>
          </el-form-item>

          <el-form-item
            :error="
              form.errors.first('date') ? form.errors.first('date') : null
            "
            label="Date"
          >
            <el-date-picker
              v-model="data.date"
              type="date"
              format="M/d/yyyy"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="Campaign">
            <el-checkbox v-model="existingCampaign"
              >Insert into an exisiting Campaign</el-checkbox
            >
          </el-form-item>

          <el-form-item
            :error="
              form.errors.first('newCampaign')
                ? form.errors.first('newCampaign')
                : null
            "
            label=" "
            v-if="!existingCampaign"
          >
            <el-input
              placeholder="Campaign Name"
              v-model="data.newCampaign"
            ></el-input>
          </el-form-item>

          <el-form-item
            :error="
              form.errors.first('existingCampaign')
                ? form.errors.first('existingCampaign')
                : null
            "
            label=" "
            v-else
          >
            <modal-select>
              <el-select
                v-model="data.existingCampaign"
                :style="{ width: '100%' }"
                @focus="form.errors.clear('existingCampaign')"
                filterable
              >
                <el-option-group
                  v-for="group in campaigns"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.data"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-option-group>
              </el-select>
            </modal-select>
          </el-form-item>

          <el-button
            type="primary"
            plain
            medium
            native-type="submit"
            :style="{ width: '100%' }"
            :disabled="loading"
            v-loading="loading"
            >Submit</el-button
          >
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"
export default {
  name: "TrackerTemplateForm",
  props: {
    tracker: {
      required: true,
    },
  },
  data() {
    return {
      form: new Form(),
      loading: false,
      data: {
        type: "Xlsx",
        file: null,
        template: null,
        date: this.$moment().subtract(1, "days"),
        existingCampaign: null,
        newCampaign: null,
      },
      existingCampaign: true,
    }
  },
  computed: {
    campaigns() {
      return this.$_.orderBy(
        this.$_.map(
          this.$_.groupBy(this.tracker.campaigns, "service"),
          (d, k) => {
            return {
              label: k,
              data: d,
            }
          }
        ),
        "label"
      )
    },
  },
  watch: {
    "data.template": {
      handler(val) {
        let template = this.$_.find(this.tracker.worksheet_templates, {
          id: val,
        })
        if (template) this.data.type = template.file_type
      },
    },
    existingCampaign() {
      this.data.newCampaign = this.data.existingCampaign = null
    },
  },
  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    processFile(data) {
      this.data.file = data.file
      this.form.errors.clear("file")
    },
    async submit() {
      this.loading = true
      let formData = new FormData()
      formData.append("file", this.data.file)
      formData.append("type", this.data.type)
      formData.append("template", this.data.template)
      formData.append("existingCampaign", this.existingCampaign)
      formData.append("newCampaignName", this.data.newCampaign)
      formData.append("existingCampaignId", this.data.existingCampaign)
      formData.append("worksheet", this.data.worksheet)
      formData.append("date", this.$moment(this.data.date).format("YYYY-MM-DD"))

      await this.axios
        .post(
          "tracker/" +
            this.$route.params.company +
            "/worksheet/storeFromTemplate",
          formData
        )
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-template-form")
          this.loading = false
        })
        .catch((resp) => {
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
