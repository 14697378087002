<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Manual Data Upload</span>
      <el-button type="text" @click="$modal.hide('tracker-manual-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <div v-if="sheet === null">
      <el-form
        :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
        label-width="100px"
        v-loading="loading"
      >
        <el-form-item
          :error="form.errors.first('type') ? form.errors.first('type') : null"
          label="File Type"
        >
          <modal-select>
            <el-select
              v-model="data.type"
              :style="{ width: '100%' }"
              @focus="form.errors.clear('type')"
            >
              <el-option label="Excel" value="Xlsx"></el-option>
              <el-option label="Excel (xls)" value="Xls"></el-option>
              <el-option label="CSV" value="Csv"></el-option>
            </el-select>
          </modal-select>
        </el-form-item>

        <el-form-item
          :error="form.errors.first('file') ? form.errors.first('file') : null"
          label="File"
        >
          <el-upload
            :http-request="uploadFile"
            action="#"
            ref="upload"
            :show-file-list="false"
          >
            <el-button
              slot="trigger"
              size="small"
              type="primary"
              :disabled="loading"
              >Select file</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <div style="margin-bottom: 0.5rem">
        <el-radio v-model="data.mutiCell" :label="false">Single Cell</el-radio>
        <el-radio v-model="data.mutiCell" :label="true">Multi Cell</el-radio>
      </div>

      <p style="margin-bottom: 0.5rem">
        Below is the contents of the spreadsheet uploaded. Please first choose a
        worksheet then use the buttons to select which cell corresponds to which
        metric. Shift-click to select all cells below. The "Impressions" metric
        is required.
      </p>
      <div style="margin-bottom: 0.5rem">
        <el-tag
          class="metric-select"
          type="success"
          @click.native="selectMetric('impressions')"
          :class="{ selected: selectedMetric === 'impressions' }"
          >Impressions</el-tag
        >
        <el-tag
          class="metric-select"
          type="info"
          @click.native="selectMetric('clicks')"
          :class="{ selected: selectedMetric === 'clicks' }"
          >Clicks</el-tag
        >
        <el-tag
          class="metric-select"
          type="warning"
          @click.native="selectMetric('conversions')"
          :class="{ selected: selectedMetric === 'conversions' }"
          >Conversions</el-tag
        >
        <el-tag
          class="metric-select"
          type="danger"
          @click.native="selectMetric('cost')"
          :class="{ selected: selectedMetric === 'cost' }"
          >Cost</el-tag
        >
      </div>
      <el-tabs type="card" @tab-click="changeWorksheet">
        <el-tab-pane
          v-for="(worksheet, key) in sheet"
          :key="key"
          :label="worksheet.name"
        >
          <div class="worksheet-table-wrapper" v-loading="loading">
            <table class="worksheet-table">
              <thead>
                <tr v-if="!selectedMetric">
                  <th></th>
                  <th
                    v-for="(col, k) in Object.keys(worksheet.data[1])"
                    :key="'col-' + k"
                  >
                    {{ col }}
                  </th>
                </tr>
                <tr v-else>
                  <th
                    class="selected-header"
                    :class="selectedMetric"
                    :colspan="Object.keys(worksheet.data[1]).length + 1"
                  >
                    Click a cell below to assign it as the
                    <span style="font-weight: bold">{{
                      selectedMetric.charAt(0).toUpperCase() +
                      selectedMetric.slice(1)
                    }}</span>
                    metric.
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, k) in worksheet.data" :key="'row-' + k">
                  <td>{{ k }}</td>
                  <td
                    v-for="(cell, j) in row"
                    :key="'cell-' + j"
                    :class="cellClass(k, j)"
                    @click="selectCell($event, k, j)"
                  >
                    {{ cell }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-tab-pane>
      </el-tabs>

      <p style="margin: 0.5rem auto">
        Please fill in the rest of the below fields and submit for processing.
      </p>

      <el-form
        @submit.native.prevent="submit"
        :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
        label-width="100px"
      >
        <el-form-item
          :error="form.errors.first('date') ? form.errors.first('date') : null"
          label="Date"
        >
          <el-date-picker
            v-model="data.date"
            type="date"
            format="M/d/yyyy"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="Campaign">
          <el-checkbox v-model="existingCampaign"
            >Insert into an exisiting Campaign</el-checkbox
          >
        </el-form-item>

        <el-form-item
          :error="
            form.errors.first('newCampaign')
              ? form.errors.first('newCampaign')
              : null
          "
          label=" "
          v-if="!existingCampaign"
        >
          <el-input
            placeholder="Campaign Name"
            v-model="data.newCampaign"
          ></el-input>
        </el-form-item>

        <el-form-item
          :error="
            form.errors.first('existingCampaign')
              ? form.errors.first('existingCampaign')
              : null
          "
          label=" "
          v-else
        >
          <modal-select>
            <el-select
              v-model="data.existingCampaign"
              :style="{ width: '100%' }"
              @focus="form.errors.clear('existingCampaign')"
              filterable
            >
              <el-option-group
                v-for="group in campaigns"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-option-group>
            </el-select>
          </modal-select>
        </el-form-item>

        <el-row>
          <el-col :span="6">
            <el-form-item
              :error="
                form.errors.first('impressions')
                  ? form.errors.first('impressions')
                  : null
              "
              label="Impr."
            >
              <el-input
                v-model="data.selectedCells.impressions"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :error="
                form.errors.first('clicks') ? form.errors.first('clicks') : null
              "
              label="Clicks"
            >
              <el-input
                v-model="data.selectedCells.clicks"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :error="
                form.errors.first('conversions')
                  ? form.errors.first('conversions')
                  : null
              "
              label="Conv."
            >
              <el-input
                v-model="data.selectedCells.conversions"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :error="
                form.errors.first('cost') ? form.errors.first('cost') : null
              "
              label="Cost"
            >
              <el-input
                v-model="data.selectedCells.cost"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          :error="
            form.errors.first('saveAs') ? form.errors.first('saveAs') : null
          "
          label="Save"
        >
          <el-checkbox v-model="save">Save settings as a template</el-checkbox>

          <el-input
            v-if="save"
            placeholder="Template Name"
            v-model="data.saveAs"
          ></el-input>
        </el-form-item>

        <el-button-group :style="{ width: '100%' }">
          <el-button
            plain
            medium
            :style="{ width: '50%' }"
            @click.native="back"
            :disabled="loading"
            v-loading="loading"
            >Back</el-button
          >
          <el-button
            type="primary"
            plain
            medium
            native-type="submit"
            :style="{ width: '50%' }"
            :disabled="loading"
            v-loading="loading"
            >Submit</el-button
          >
        </el-button-group>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { mapActions } from "vuex"
import Form from "form-backend-validation"
export default {
  name: "TrackerManualForm",
  props: {
    tracker: {
      required: true,
    },
  },
  data() {
    return {
      form: new Form(),
      loading: false,
      data: {},
      defaultData: {
        type: "Xlsx",
        file: null,
        selectedCells: {
          impressions: null,
          clicks: null,
          conversions: null,
          cost: null,
        },
        newCampaign: null,
        existingCampaign: null,
        worksheet: 0,
        date: this.$moment().subtract(1, "days"),
        saveAs: null,
        mutiCell: true,
      },
      existingCampaign: false,
      save: true,
      sheet: null,
      selectedMetric: null,
    }
  },
  watch: {
    existingCampaign() {
      this.data.newCampaign = this.data.existingCampaign = null
    },
    save(val) {
      if (!val) this.data.saveAs = null
    },
  },
  computed: {
    campaigns() {
      return this.$_.orderBy(
        this.$_.map(
          this.$_.groupBy(this.tracker.campaigns, "service"),
          (d, k) => {
            return {
              label: k,
              data: d,
            }
          }
        ),
        "label"
      )
    },
  },
  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    async uploadFile(data) {
      this.loading = true
      this.data.file = data.file
      let formData = new FormData()
      formData.append("file", data.file)
      formData.append("type", this.data.type)

      await this.axios
        .post(
          "tracker/" + this.$route.params.company + "/worksheet/show",
          formData
        )
        .then((response) => {
          this.sheet = response.data.data
        })
        .catch((resp) => {
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })

      this.loading = false
    },
    async submit() {
      this.loading = true
      let formData = new FormData()
      formData.append("file", this.data.file)
      formData.append("type", this.data.type)
      formData.append("impressions", this.data.selectedCells.impressions)
      formData.append("clicks", this.data.selectedCells.clicks)
      formData.append("conversions", this.data.selectedCells.conversions)
      formData.append("cost", this.data.selectedCells.cost)
      formData.append("existingCampaign", this.existingCampaign)
      formData.append("newCampaignName", this.data.newCampaign)
      formData.append("existingCampaignId", this.data.existingCampaign)
      formData.append("worksheet", this.data.worksheet)
      formData.append("date", this.$moment(this.data.date).format("YYYY-MM-DD"))
      formData.append("save", this.save)
      formData.append("saveAs", this.data.saveAs)
      formData.append("multicell", this.data.mutiCell)

      await this.axios
        .post(
          "tracker/" + this.$route.params.company + "/worksheet/store",
          formData
        )
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-manual-form")
          this.loading = false
        })
        .catch((resp) => {
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })

      this.loading = false
    },
    changeWorksheet(tab) {
      this.data.worksheet = parseInt(tab.$el.id.replace("pane-", ""))
      this.data.selectedCells = {
        impressions: null,
        clicks: null,
        conversions: null,
        cost: null,
      }
    },
    selectMetric(metric) {
      if (this.selectedMetric === metric) {
        this.selectedMetric = null
      } else {
        this.selectedMetric = metric
      }
    },
    selectCell(e, row, col) {
      if (!this.selectedMetric) return
      this.loading = true
      this.data.selectedCells[this.selectedMetric] =
        this.data.selectedCells[this.selectedMetric] !== col + row
          ? col + row
          : null

      this.selectedMetric = null
      this.loading = false
    },
    cellClass(row, col) {
      let str = ""
      if (this.selectedMetric) {
        str += "selectable"
      }
      if (!this.data.mutiCell) {
        if (this.data.selectedCells.impressions === col + row) {
          str += " impressions"
        } else if (this.data.selectedCells.clicks === col + row) {
          str += " clicks"
        } else if (this.data.selectedCells.conversions === col + row) {
          str += " conversions"
        } else if (this.data.selectedCells.cost === col + row) {
          str += " cost"
        }
      } else {
        if (
          this.data.selectedCells.impressions &&
          this.data.selectedCells.impressions.charAt(0) === col &&
          parseInt(this.data.selectedCells.impressions.charAt(1)) <= row
        ) {
          str += " impressions"
        } else if (
          this.data.selectedCells.clicks &&
          this.data.selectedCells.clicks.charAt(0) === col &&
          parseInt(this.data.selectedCells.clicks.charAt(1)) <= row
        ) {
          str += " clicks"
        } else if (
          this.data.selectedCells.conversions &&
          this.data.selectedCells.conversions.charAt(0) === col &&
          parseInt(this.data.selectedCells.conversions.charAt(1)) <= row
        ) {
          str += " conversions"
        } else if (
          this.data.selectedCells.cost &&
          this.data.selectedCells.cost.charAt(0) === col &&
          parseInt(this.data.selectedCells.cost.charAt(1)) <= row
        ) {
          str += " cost"
        }
      }

      return str
    },
    back() {
      this.sheet = null
      this.data = this.$_.cloneDeep(this.defaultData)
    },
  },
  created() {
    this.data = this.$_.cloneDeep(this.defaultData)
  },
}
</script>

<style lang="scss" scoped>
.worksheet-table-wrapper {
  overflow: auto;
  max-height: 75vh;

  .worksheet-table {
    width: 100%;
    border-spacing: 0;
    thead {
      th {
        background: #ccc;
        text-align: center;
        font-weight: normal;
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
      }
      th.selected-header {
        &.impressions {
          color: #67c23a;
          background-color: rgba(103, 194, 58, 0.1);
        }
        &.clicks {
          color: #409eff;
          background-color: rgba(64, 158, 255, 0.1);
        }
        &.conversions {
          color: #e6a23c;
          background-color: rgba(230, 162, 60, 0.1);
        }
        &.cost {
          color: #f56c6c;
          background-color: rgba(245, 108, 108, 0.1);
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          background: #ccc;
          text-align: center;
          font-size: 0.8rem;
          min-width: 1rem;
        }
        td {
          padding: 0.25rem 0.5rem;
          white-space: nowrap;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;

          &.selectable {
            cursor: pointer;
          }
          &.impressions {
            color: #67c23a;
            background-color: rgba(103, 194, 58, 0.1);
          }
          &.clicks {
            color: #409eff;
            background-color: rgba(64, 158, 255, 0.1);
          }
          &.conversions {
            color: #e6a23c;
            background-color: rgba(230, 162, 60, 0.1);
          }
          &.cost {
            color: #f56c6c;
            background-color: rgba(245, 108, 108, 0.1);
          }
        }
      }
    }
  }
}

.metric-select {
  margin: 0 0.5rem;
  cursor: pointer;

  &.selected {
    font-weight: bold;
  }
}
</style>
