<template>
  <el-row v-if="data" style="font-size: 0.9rem">
    <el-alert
      v-if="data['AW'] !== 'success'"
      :type="data['AW']"
      :title="data['AW'] === 'error' ? 'Error' : 'Warning'"
      :closable="false"
      style="margin-bottom: 0.5rem"
    >
      There are currently {{ counts["AW"] }} active AdWords accounts. The limit
      from Supermetrics is {{ counts.limit }}.{{
        data["AW"] === "error"
          ? " Please pause other trackers before adding more."
          : ""
      }}
    </el-alert>
    <el-alert
      v-if="data['FA'] !== 'success'"
      :type="data['FA']"
      :title="data['FA'] === 'error' ? 'Error' : 'Warning'"
      :closable="false"
      style="margin-bottom: 0.5rem"
    >
      There are currently {{ counts["FA"] }} active Facebook accounts. The limit
      from Supermetrics is {{ counts.limit }}.{{
        data["FA"] === "error"
          ? " Please pause other trackers before adding more."
          : ""
      }}
    </el-alert>
    <el-alert
      v-if="data['AC'] !== 'success'"
      :type="data['AC']"
      :title="data['AC'] === 'error' ? 'Error' : 'Warning'"
      :closable="false"
      style="margin-bottom: 0.5rem"
    >
      There are currently {{ counts["AC"] }} active AdWords accounts. The limit
      from Supermetrics is {{ counts.limit }}.{{
        data["AC"] === "error"
          ? " Please pause other trackers before adding more."
          : ""
      }}
    </el-alert>
  </el-row>
</template>

<script>
export default {
  name: "TrackerSupermetricsCounts",
  props: {
    counts: {
      required: true,
    },
  },
  computed: {
    data() {
      if (!this.counts) return null
      return {
        AW:
          this.counts["AW"] > this.counts.limit * 0.8
            ? this.counts["AW"] > this.counts.limit
              ? "error"
              : "warning"
            : "success",
        FA:
          this.counts["FA"] > this.counts.limit * 0.8
            ? this.counts["FA"] > this.counts.limit
              ? "error"
              : "warning"
            : "success",
        AC:
          this.counts["AC"] > this.counts.limit * 0.8
            ? this.counts["AC"] > this.counts.limit
              ? "error"
              : "warning"
            : "success",
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
