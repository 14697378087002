<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Simpli.fi Integration Settings</span>
      <el-button type="text" @click="$modal.hide('tracker-simplifi-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('id') ? form.errors.first('id') : null"
        label="Organization"
        required
      >
        <el-input
          v-model="id"
          type="text"
          @focus="form.errors.clear('id')"
          :disabled="loading"
          @keypress.native="limitInput"
        >
          <template slot="append">
            <el-button
              @click="addOrg"
              :disabled="!id.length"
              v-loading="loading"
              >Add</el-button
            >
          </template>
        </el-input>
      </el-form-item>
      <p class="help-text" style="margin-bottom: 0.5rem">
        Enter Simpli.fi Organization ID and add it to the list.
      </p>

      <div v-if="data.ids.length">
        <el-form-item label="Orgs">
          <el-tag
            v-for="id in data.ids"
            :key="id.id"
            type="info"
            closable
            @close="removeId(id)"
            style="margin-right: 10px"
          >
            {{ id.value }}
          </el-tag>
        </el-form-item>

        <p style="margin-bottom: 0.5rem">Campaign Filters:</p>
        <p class="help-text">
          If given, these value will filter statistics to only campaign names
          (for the given organization) containing this string. These values are
          case insensitive, but do include special characters.
        </p>

        <el-row
          style="margin-bottom: 0.5rem"
          v-for="id in data.ids"
          :key="id.id"
        >
          <hr style="margin-top: 1rem" />
          <el-col :span="24">
            <el-row style="margin-bottom: 0.5rem">
              <el-col :span="12"> {{ id.value }} Filters: </el-col>
            </el-row>
            <el-row
              v-for="filter in id.filters"
              :key="filter.id"
              style="margin-bottom: 0.5rem"
            >
              <el-col
                class="el-form-item"
                :span="11"
                style="padding-right: 5px"
              >
                <modal-select>
                  <el-select
                    v-model="filter.operator"
                    :style="{ width: '100%' }"
                    placeholder="Operator"
                  >
                    <el-option label="Contains" value="contains"></el-option>
                    <el-option
                      label="Does Not Contain"
                      value="does_not_contain"
                    ></el-option>
                  </el-select>
                </modal-select>
              </el-col>

              <el-col
                class="el-form-item"
                :span="12"
                style="padding-left: 5px; padding-right: 5px"
              >
                <el-input
                  v-model="filter.value"
                  type="text"
                  placeholder="Value"
                ></el-input>
              </el-col>

              <el-col :span="1" style="padding-left: 5px; text-align: right">
                <a
                  href="javascript:void(0)"
                  @click="removeFilter(id.filters, filter.id)"
                >
                  <font-awesome-icon
                    icon="trash"
                    class="icon"
                    fixed-width
                  ></font-awesome-icon>
                </a>
              </el-col>
            </el-row>

            <el-row style="text-align: right">
              <el-button type="primary" @click="addFilter(id.filters)"
                >Add</el-button
              >
            </el-row>
          </el-col>
        </el-row>

        <el-button
          type="primary"
          plain
          medium
          native-type="submit"
          :style="{ width: '100%' }"
          v-loading="loading"
          :disabled="loading"
        >
          Submit
        </el-button>
      </div>
    </el-form>
  </el-card>
</template>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerSimplifiForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },

  data() {
    return {
      id: "",
      data: {
        ids: [],
        filter: null,
        filter_operator: "none",
        filters: [],
      },
      operators: {
        none: "None",
        contains: "Contains",
        does_not_contain: "Does Not Contain",
      },
      campaigns: [],
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    action() {
      return "tracker/" + this.company.slug + "/simplifi/update"
    },
    campaignsAction() {
      return "tracker/" + this.company.slug + "/simplifi/campaigns"
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    limitInput(evt, commas = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 47 && charCode < 58) {
        return true
      } else if (commas && charCode === 44) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    async addOrg() {
      this.loading = true
      await this.axios
        .get(this.campaignsAction + "?id=" + this.id)
        .then(({ data }) => {
          this.campaigns.push(data.data)
          if (this.data.ids.length === 0) {
            this.data.ids.push({
              id: 1,
              value: this.id,
              filters: [],
            })
          } else {
            let max = this.$_.maxBy(this.data.ids, "id"),
              id = max.id + 1

            this.data.ids.push({
              id: id,
              value: this.id,
              filters: [],
            })
          }
          this.id = ""
        })
        .catch((resp) => {
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
      this.loading = false
    },
    submit() {
      this.loading = true

      let formData = {}

      formData = {
        ids: this.data.ids,
        filter_operator: this.data.filter_operator,
        filter: this.data.filter,
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-simplifi-form")
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
    removeId(id) {
      let item = this.$_.findIndex(this.data.ids, ["id", id.id])

      this.data.ids.splice(item, 1)
    },
    addFilter(filters) {
      if (filters.length === 0) {
        filters.push({
          id: 1,
          operator: "contains",
          value: "",
        })
      } else {
        let max = this.$_.maxBy(filters, "id"),
          id = max.id + 1

        filters.push({
          id: id,
          operator: "contains",
          value: "",
        })
      }
    },
    removeFilter(filters, id) {
      let item = this.$_.findIndex(filters, ["id", id])

      filters.splice(item, 1)
    },
  },

  created() {
    if (this.existing !== false) {
      let ids = []
      let filters = []
      for(let x = 0; x < this.existing.ids.length; x++) {
        filters = []
        for(let y = 0; y < this.existing.filters[this.existing.ids[x]].length; y++) {
          filters.push({
            id: x + 1,
            operator: this.existing.filters[this.existing.ids[x]][y]['operator'],
            value: this.existing.filters[this.existing.ids[x]][y]['value'],
          })
        }
        ids.push({
          id: x + 1,
          value: this.existing.ids[x],
          filters: filters
        })
      }
      this.data.ids = ids
    }
  },
  mounted() {},
}
</script>
