<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>{{ cardTitle }}</span>
      <el-button
        type="text"
        @click="$modal.hide('tracker-edit-service-data-form')"
      >
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="150px"
    >
      <el-form-item label="">
        <el-checkbox v-model="inherit"> Inherit Settings </el-checkbox>
      </el-form-item>

      <template v-if="!inherit" style="margin-bottom: 1rem">
        <el-alert
          :title="inheritedText"
          type="info"
          :closable="false"
          v-if="this.existing.conversions_inherited"
        >
        </el-alert>
        <el-form-item
          :error="
            form.errors.first('conversions')
              ? form.errors.first('conversions')
              : null
          "
        >
          <el-checkbox v-model="service.conversions">
            Show Conversions
          </el-checkbox>
        </el-form-item>
        <el-form-item
          :error="
            form.errors.first('conversions_label')
              ? form.errors.first('conversions_label')
              : form.errors.first('conversions_abbreviation')
              ? form.errors.first('conversions_abbreviation')
              : null
          "
          label="Label"
          v-if="service.conversions"
        >
          <el-row>
            <el-col :span="18" style="padding-right: 0.5rem">
              <el-input
                v-model="service.conversions_label"
                type="text"
                placeholder="Full"
                @focus="form.errors.clear('conversions_label')"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="service.conversions_abbreviation"
                type="text"
                placeholder="Abbrv."
                @focus="form.errors.clear('conversions_abbreviation')"
                maxlength="4"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </template>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        v-loading="loading.form"
        :style="{ width: '100%' }"
      >
        Save
      </el-button>
    </el-form>
  </el-card>
</template>

<style lang="scss">
</style>

<script>
import Form from "form-backend-validation"
import { mapActions } from "vuex"

export default {
  name: "TrackerEditServiceForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  data() {
    return {
      service: {},
      inherit: true,
      form: new Form(),
      loading: {
        form: false,
      },
    }
  },
  computed: {
    cardTitle() {
      let service = ""
      if (this.type === "display") {
        service = "Display"
      } else if (this.type === "sem") {
        service = "SEM"
      } else if (this.type === "google-display") {
        service = "Google Display"
      } else if (this.type === "facebook-display") {
        service = "Facebook Display"
      } else if (this.type === "google-sem") {
        service = "Google SEM"
      } else if (this.type === "bing-sem") {
        service = "Bing SEM"
      }

      return "Modify " + service + " Settings"
    },
    inheritedText() {
      let text = ""
      if (!this.existing.conversions_inherited) return text
      if (this.existing.conversions_inherited === "type") {
        if (["google-display", "facebook-display"].indexOf(this.type) !== -1) {
          text = "Display"
        } else {
          text = "SEM"
        }
      } else {
        text = "Company"
      }
      return (
        "Settings currently inherited from " +
        text +
        ". These values have been auto filled into the inputs below."
      )
    },
    action() {
      return "tracker/" + this.$route.params.company + "/services/" + this.type
    },
  },

  methods: {
    ...mapActions({
      updateAuth: "auth/update",
      updateCompany: "tracker/updateAdmin",
    }),
    update() {
      this.loading.form = true

      let formData = {
        inherit: this.inherit,
      }

      if (!this.inherit) {
        formData.conversions = this.service.conversions
        formData.conversions_label = this.service.conversions_label
        formData.conversions_abbreviation =
          this.service.conversions_abbreviation
      }

      this.axios
        .post(this.action + "/update", formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.updateAuth()
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-edit-service-data-form")
          this.loading.form = false
        })
        .catch((resp) => {
          this.loading.form = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  mounted() {
    this.service = this.$_.cloneDeep(this.existing)

    if (this.existing.conversions_inherited === false) {
      this.inherit = false
    }
  },
}
</script>
