<template>
  <div>
    <el-alert title="No campaigns for this tracker." type="info" :closable="false" v-if="isEmpty"></el-alert>

    <div v-else>
      <div class="table-container">
        <div class="table-toolbar">
          <div style="margin-right: 0.5rem">
              <el-select @change="fetchCampaigns(1, selectedService)" v-model="selectedService" size="small">
                <el-option v-for="item in services" :key="item" :label="getServiceLabel(item)"
                  :value="item"></el-option>
              </el-select>
            </div>
            <div style="flex: 1">
              <el-input v-model="search" placeholder="Search by Name..." @input="handleSearch" size="small">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
        </div>
      </div>

      <el-table :data="current ? current : []" style="width: 100%">
        <el-table-column label="Status" width="60" align="center">
          <template slot-scope="scope">
            <font-awesome-icon icon="circle" :class="{ success: scope.row.active, danger: !scope.row.active }"
              :style="{ opacity: scope.row.visible ? '1' : '.25' }"></font-awesome-icon>
          </template>
        </el-table-column>

        <el-table-column prop="display_name" label="Name" min-width="150" show-overflow-tooltip sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.display_name }}
            <span v-if="scope.row.name && scope.row.custom_name" class="text-light">({{ scope.row.name }})</span>
          </template>
        </el-table-column>

        <el-table-column label="Start" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.custom_start">
              <el-tooltip effect="dark" :content="
                'Original Value: ' +
                (scope.row.start
                  ? dateTimeFormatString(
                      scope.row.start,
                      'MM/DD/YY',
                      'YYYY-MM-DD'
                    )
                  : '---'
                ).toString()
              " placement="bottom">
                <span>
                  {{
                  dateTimeFormatString(
                  scope.row.custom_start,
                  "MM/DD/YY",
                  "YYYY-MM-DD"
                  )
                  }}
                  <font-awesome-icon icon="asterisk" fixed-width></font-awesome-icon>
                </span>
              </el-tooltip>
            </span>
            <span v-else>
              {{
              scope.row.start
              ? dateTimeFormatString(
              scope.row.start,
              "MM/DD/YY",
              "YYYY-MM-DD"
              )
              : "---"
              }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="End" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.custom_end">
              <el-tooltip effect="dark" :content="
                'Original Value: ' +
                (scope.row.end
                  ? dateTimeFormatString(
                      scope.row.end,
                      'MM/DD/YY',
                      'YYYY-MM-DD'
                    )
                  : '---'
                ).toString()
              " placement="bottom">
                <span>
                  {{
                  dateTimeFormatString(
                  scope.row.custom_end,
                  "MM/DD/YY",
                  "YYYY-MM-DD"
                  )
                  }}
                  <font-awesome-icon icon="asterisk" fixed-width></font-awesome-icon>
                </span>
              </el-tooltip>
            </span>
            <span v-else>
              {{
              scope.row.start
              ? dateTimeFormatString(scope.row.end, "MM/DD/YY", "YYYY-MM-DD")
              : "---"
              }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="updated_at" label="Last Updated" width="200" sortable="custom">
          <template slot-scope="scope">
            {{ dateTimeFormat(scope.row.updated_at) }}
          </template>
        </el-table-column>

        <el-table-column label="" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" style="padding: 0"
              @click="openTrackerCampaignEditModal(scope.row)">Modify</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="20" class="pagination"
      layout="prev, pager, next" :total="totalItems">
    </el-pagination>
    </div>
  </div>
</template>


<style lang="scss">
.pagination {
  text-align: center;
}

.table-title {
  display: flex;
  border-bottom: 2px solid #d4a100;
  padding-right: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #303133;
}

.table-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 0.5rem;
}

.table-toolbar {
  display: flex;
  justify-content: flex-end;
  width: 500px;
  max-width: 100%;
  background: #fafafa;
  padding: .25rem .5rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
}
</style>


<script>
import DateTime from "@/mixins/DateTime"
import TrackerCampaignEditForm from "@/components/Forms/TrackerCampaignEditForm"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"
import { mapActions, mapGetters } from "vuex"
import { debounce } from 'lodash'

export default {
  name: "TrackerCampaignTable",

  mixins: [DateTime, TrackerServiceLogos],
  components: {},

  props: {
    company: {
      required: true,
    },
  },

  data() {
    return {
      searchDef: {
        value: "",
        prop: "display_name",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      selectedService: null,
      services: [],
      currentPage: 1,
      search: '',
    }
  },
  computed: {
    ...mapGetters('campaigns', ['list', 'totalItems']),
    campaigns() {
      return this.list;
    },
    data() {
      if (!this.campaigns) return
      return this.$_.groupBy(
        this.$_.orderBy(
          this.$_.map(this.campaigns, (item) => {
            return this.$_.merge(item, {
              display_name: item.custom_name ? item.custom_name : item.name,
            })
          }),
          (item) => {
            return item.updated_at
          }
        ),
        (item) => {
          return item.service
        }
      )
    },
    isEmpty() {
      if (!this.campaigns) return true
      return this.campaigns.length === 0
    },
    current() {
      if (this.isEmpty || !this.selectedService) return []
      return this.data[this.selectedService]
    },
  },

  methods: {
    ...mapActions('campaigns', ['refresh', 'servicesList']),
    fetchCampaigns(page = 1, service = this.selectedService, search = this.search) {
      this.currentPage = page
      this.refresh({ page, service, query: search });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchCampaigns(page, this.selectedService);
    },
    async getServices() {
    try {
      const services = await this.servicesList();
      this.services = services;
      this.selectedService = this.services[0];
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  },
    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchCampaigns(this.currentPage, this.selectedService);
    }, 500),
    openTrackerCampaignEditModal(row) {
      this.$modal.show(
        TrackerCampaignEditForm,
        {
          existing: row,
          company: this.company.slug,
        },
        {
          name: "tracker-campaign-edit-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    getServiceLabel(service) {
      if (service === "google-display") return "Google Display"
      if (service === "google-sem") return "Google SEM"
      if (service === "google-video") return "Google Video"
      if (service === "facebook-display") return "Facebook Display"
      if (service === "facebook-video") return "Facebook Video"
      if (service === "agility-display") return "AgilityAds Display"
      if (service === "agility-tv") return "AgilityAds TV"
      if (service === "bing-sem") return "Bing SEM"
      if (service === "siteimpact-mail") return "SiteImpact"
      if (service === "tremor-tv") return "Tremor"
      if (service === "simplifi-tv") return "Simpli.fi TV"
      if (service === "simplifi-display") return "Simpli.fi Display"
      if (service === "mailchimp-mail") return "MailChimp"
      if (service === "emailresultz-mail") return "Email Resultz"
      if (service === "constantcontact-mail") return "Constant Contact"
      if (service === "settop-tv") return "Set Top"
    },
  },

  watch: {},

  created() {
    this.getServices().then(() => {
      this.fetchCampaigns(this.currentPage, this.selectedService);
    });
  },
}
</script>
