<template>
  <el-card v-if="data">
    <el-row type="flex" style="margin-bottom: 0.5rem">
      <el-col style="flex: 1">
        <h5>Integrations</h5>
      </el-col>
      <el-col style="flex: 0; white-space: nowrap">
        <el-button type="default" size="medium" @click="openWorksheetModal">
          <font-awesome-icon icon="upload" fixed-width></font-awesome-icon> New
          Manual Upload
        </el-button>
        <el-button type="primary" size="medium" @click="openTemplateModal">
          <font-awesome-icon icon="file-upload" fixed-width></font-awesome-icon>
          Manual Upload from Template
        </el-button>
      </el-col>
    </el-row>

    <el-alert
      v-if="data.paused"
      title="Tracker Paused!"
      description="If an integration is edited or added new data will not be gathered until this tracker is reactivated."
      type="warning"
      :closable="false"
      style="margin-bottom: 1rem"
    ></el-alert>
    <tracker-integrations-table></tracker-integrations-table>
  </el-card>
</template>

<style scoped>
</style>

<script>
import TrackerIntegrationsTable from "@/components/Tables/TrackerIntegrationsTable"
import TrackerManualForm from "@/components/Forms/TrackerManualForm"
import TrackerTemplateForm from "@/components/Forms/TrackerTemplateForm"

export default {
  name: "TrackerCompanyIntegrations",

  mixins: [],
  components: {
    TrackerIntegrationsTable,
  },

  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},

  methods: {
    openWorksheetModal() {
      this.$modal.show(
        TrackerManualForm,
        {
          tracker: this.data,
        },
        {
          name: "tracker-manual-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
          width: 1000,
          clickToClose: false,
        }
      )
    },
    openTemplateModal() {
      this.$modal.show(
        TrackerTemplateForm,
        {
          tracker: this.data,
        },
        {
          name: "tracker-template-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
          width: 1000,
          clickToClose: false,
        }
      )
    },
  },

  created() {},
  mounted() {},
}
</script>
