<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Update Tracker Campaign</span>
      <el-button type="text" @click="$modal.hide('tracker-campaign-edit-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item label="Default Name">
        <el-input v-model="existing.name" type="text" disabled></el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('custom_name')
            ? form.errors.first('custom_name')
            : null
        "
        label="Custom Name"
      >
        <el-input
          v-model="campaign.custom_name"
          type="text"
          placeholder="Custom Name"
          @focus="form.errors.clear('custom_name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-row>
        <el-col :md="12">
          <el-form-item
            :error="
              form.errors.first('custom_start')
                ? form.errors.first('custom_start')
                : null
            "
            label="Custom Start"
          >
            <el-date-picker
              v-model="campaign.custom_start"
              align="center"
              format="M/d/yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              @focus="form.errors.clear('custom_start')"
              clearable
              :picker-options="startPickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item
            :error="
              form.errors.first('custom_end')
                ? form.errors.first('custom_end')
                : null
            "
            label="Custom End"
          >
            <el-date-picker
              v-model="campaign.custom_end"
              align="center"
              format="M/d/yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              @focus="form.errors.clear('custom_end')"
              clearable
              :picker-options="endPickerOptions"
              :disabled="campaign.custom_start === null"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="">
        <el-row>
          <el-col :md="12">
            <el-checkbox v-model="campaign.visible"> Visible </el-checkbox>
          </el-col>
          <el-col :md="12">
            <el-checkbox v-model="inherit"> Inherit Settings </el-checkbox>
          </el-col>
        </el-row>
      </el-form-item>

      <el-card v-if="!inherit" style="margin-bottom: 1rem">
        <el-alert
          :title="inheritedText"
          type="info"
          :closable="false"
          v-if="this.existing.conversions_inherited"
        >
        </el-alert>
        <el-form-item
          :error="
            form.errors.first('conversions')
              ? form.errors.first('conversions')
              : null
          "
        >
          <el-checkbox v-model="campaign.conversions">
            Show Conversions
          </el-checkbox>
        </el-form-item>
        <el-form-item
          :error="
            form.errors.first('conversions_label')
              ? form.errors.first('conversions_label')
              : form.errors.first('conversions_abbreviation')
              ? form.errors.first('conversions_abbreviation')
              : null
          "
          label="Label"
          v-if="campaign.conversions"
        >
          <el-row>
            <el-col :span="18" style="padding-right: 0.5rem">
              <el-input
                v-model="campaign.conversions_label"
                type="text"
                placeholder="Full"
                @focus="form.errors.clear('conversions_label')"
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="campaign.conversions_abbreviation"
                type="text"
                placeholder="Abbrv."
                @focus="form.errors.clear('conversions_abbreviation')"
                maxlength="4"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-card>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Save
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import moment from "moment"
import { mapActions } from "vuex"

export default {
  name: "TrackerCampaignEditForm",

  mixins: [],

  props: {
    existing: {
      required: true,
    },
    company: {
      required: true,
    },
  },
  data() {
    return {
      campaign: {},
      inherit: true,
      form: new Form(),
      loading: false,
      startPickerOptions: {
        disabledDate: (time) => {
          if (this.campaign.custom_end) {
            let end = parseInt(
              moment(this.campaign.custom_end, "YYYY-MM-DD")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .format("x")
            )
            return (
              time.getTime() >= parseInt(moment().format("x")) ||
              time.getTime() >= end
            )
          }
        },
        firstDayOfWeek: 7,
      },
      endPickerOptions: {
        disabledDate: (time) => {
          let start = parseInt(
            moment(this.campaign.custom_start, "YYYY-MM-DD")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .format("x")
          )
          return time.getTime() <= start
        },
        firstDayOfWeek: 7,
      },
    }
  },
  computed: {
    action() {
      return (
        "tracker/" + this.company + "/campaign/" + this.existing.id + "/update"
      )
    },
    inheritedText() {
      let text = ""
      if (!this.existing.conversions_inherited) return text
      if (this.existing.conversions_inherited === "type") {
        if (
          ["google-display", "facebook-display"].indexOf(
            this.existing.service
          ) !== -1
        ) {
          text = "Display"
        } else {
          text = "SEM"
        }
      } else if (this.existing.conversions_inherited === "service") {
        text = "Service"
      } else {
        text = "Company"
      }
      return (
        "Settings currently inherited from " +
        text +
        ". These values have been auto filled into the inputs below."
      )
    },
  },
  watch: {
    "campaign.custom_start": {
      handler(val) {
        if (val === null) this.campaign.custom_end = null
      },
    },
  },

  methods: {
    ...mapActions({
      updateAdmin: "tracker/updateAdmin",
    }),
    update() {
      this.loading = true

      let formData = {
        custom_name: this.campaign.custom_name,
        custom_start: this.campaign.custom_start,
        custom_end: this.campaign.custom_end,
        visible: this.campaign.visible,
        inherit: this.inherit,
      }

      if (!this.inherit) {
        formData.conversions = this.campaign.conversions
        formData.conversions_label = this.campaign.conversions_label
        formData.conversions_abbreviation =
          this.campaign.conversions_abbreviation
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          this.updateAdmin(response.data.data)
          this.$modal.hide("tracker-campaign-edit-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    this.campaign = {
      custom_name: this.existing.custom_name,
      custom_start: this.existing.custom_start,
      custom_end: this.existing.custom_end,
      visible: this.existing.visible,
      conversions: this.existing.conversions,
      conversions_label: this.existing.conversions_label,
      conversions_abbreviation: this.existing.conversions_abbreviation,
    }

    if (this.existing.conversions_inherited === false) {
      this.inherit = false
    }
  },
}
</script>
