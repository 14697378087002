<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Siteimpact Integration Settings</span>
      <el-button type="text" @click="$modal.hide('tracker-siteimpact-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('slugs') ? form.errors.first('slugs') : null"
        label="Slugs"
        required
      >
        <el-input
          v-model="data.slugs"
          type="text"
          @focus="form.errors.clear('slugs')"
          @keypress.native="limitInput"
        ></el-input>
        <p class="help-text">
          Enter comma separated slugs. Slugs can only contain lowercase, numbers
          and hyphens.
        </p>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
        :disabled="loading"
      >
        Submit
      </el-button>
    </el-form>
  </el-card>
</template>

<style scoped>
</style>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerSiteimpactForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },

  data() {
    return {
      data: {
        slugs: "",
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    action() {
      return "tracker/" + this.company.slug + "/siteimpact/update"
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    limitInput(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        (charCode > 47 && charCode < 58) ||
        (charCode > 95 && charCode < 106) ||
        (charCode > 96 && charCode < 123) ||
        charCode === 45 ||
        charCode === 44
      ) {
        if (charCode === 44 && this.string.length === 0) {
          evt.preventDefault()
        } else if (charCode === 44 && this.string.slice(-1) === ",") {
          evt.preventDefault()
        } else if (charCode === 45 && this.string.slice(-1) === "-") {
          evt.preventDefault()
        } else {
          return true
        }
      } else {
        evt.preventDefault()
      }
    },
    submit() {
      this.loading = true

      let formData = {
        slugs: this.data.slugs,
      }

      this.axios
        .post(this.action, formData)
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
          this.$modal.hide("tracker-siteimpact-form")
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    if (this.existing !== null) {
      this.data.slugs = this.existing
    }
  },
  mounted() {},
}
</script>
