<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Delete Tracker Data</span>
      <el-button type="text" @click="$modal.hide('tracker-delete-data-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-alert
      title=""
      type="warning"
      description="You are about to delete tracker data for this Company. Once deleted, it can only be recovered by reseeding that particular data. Also be aware that if the tracker is currently set to update these services automatically, the data may restored next time an update is run."
      :closable="false"
      show-icon
      style="margin-bottom: 1rem"
    ></el-alert>

    <el-form
      @submit.native.prevent="update"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="150px"
    >
      <el-form-item label="Service">
        <modal-select>
          <el-select
            v-model="services"
            :style="{ width: '100%' }"
            clearable
            multiple
            placeholder="All Services"
            @focus="form.errors.clear('services')"
            v-input-focus
          >
            <el-option-group label="Display">
              <el-option label="Google Display" value="google-display">
                <font-awesome-icon
                  :icon="['fab', 'google']"
                  fixed-width
                ></font-awesome-icon>
                Google
              </el-option>
              <el-option label="Facebook Display" value="facebook-display">
                <font-awesome-icon
                  :icon="['fab', 'facebook-f']"
                  fixed-width
                ></font-awesome-icon>
                Facebook
              </el-option>
              <el-option label="Simpli.fi" value="simplifi-display">
                Simpli.fi
              </el-option>
            </el-option-group>
            <el-option-group label="SEM">
              <el-option label="Google SEM" value="google-sem">
                <font-awesome-icon
                  :icon="['fab', 'google']"
                  fixed-width
                ></font-awesome-icon>
                Google
              </el-option>
              <el-option label="Bing SEM" value="bing-sem">
                <font-awesome-icon
                  :icon="['fab', 'microsoft']"
                  fixed-width
                ></font-awesome-icon>
                Bing
              </el-option>
            </el-option-group>
            <el-option-group label="Mail">
              <el-option label="MailChimp" value="mailchimp-mail">
                MailChimp
              </el-option>
              <el-option label="Siteimpact" value="siteimpact-mail">
                Siteimpact
              </el-option>
              <el-option label="Email Resultz" value="emailresultz-mail">
                Email Resultz
              </el-option>
            </el-option-group>
            <el-option-group label="TV">
              <el-option label="Simplifi" value="simplifi-tv">
                Simplifi
              </el-option>
              <el-option label="Tremor" value="tremor-tv"> Tremor </el-option>
              <el-option label="Set Top" value="settop-tv"> Set Top </el-option>
            </el-option-group>
            <el-option-group label="Video">
              <el-option label="Google Video" value="google-video">
                <font-awesome-icon
                  :icon="['fab', 'google']"
                  fixed-width
                ></font-awesome-icon>
                YouTube
              </el-option>
              <el-option label="Facebook Video" value="facebook-video">
                <font-awesome-icon
                  :icon="['fab', 'facebook-f']"
                  fixed-width
                ></font-awesome-icon>
                Facebook
              </el-option>
            </el-option-group>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-form-item label="Date Range">
        <el-date-picker
          v-model="range"
          type="daterange"
          align="center"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          format="M/d/yyyy"
          value-format="yyyy-MM-dd"
          style="width: 100%"
          @focus="form.errors.clear('dates')"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="button"
        v-loading="loading.form"
        :style="{ width: '100%' }"
        @click="confirmDelete"
      >
        Continue
      </el-button>
    </el-form>
  </el-card>
</template>

<style scoped>
</style>

<script>
import Form from "form-backend-validation"
import DateTime from "@/mixins/DateTime"

export default {
  name: "TrackerDeleteDataForm",

  mixins: [DateTime],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      form: new Form(),
      loading: false,
      services: [],
      range: null,
      pickerOptions: {
        disabledDate: (time) => {
          return (
            time.getTime() >= this.existing.tracker.end * 1000 ||
            time.getTime() < this.existing.tracker.start * 1000 - 86400000
          )
        },
        firstDayOfWeek: 7,
      },
    }
  },
  computed: {
    action() {
      return "/tracker/" + this.existing.slug + "/delete-range"
    },
  },
  watch: {},

  methods: {
    confirmDelete() {
      let message = "This will permanently delete "
      if (this.services.length < 1) {
        message += "<strong>all data</strong>"
      } else {
        message += "<strong>" + this.services.join("/") + " data</strong> "
      }

      if (this.range === null) {
        message += " for <strong> all time</strong>"
      } else {
        message +=
          " between <strong>" +
          this.dateTimeFormatString(this.range[0]) +
          "</strong> and <strong>" +
          this.dateTimeFormatString(this.range[1]) +
          "</strong>"
      }
      message += ". Are you sure you want to continue?"

      this.$confirm(message, {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios
            .post(this.action, {
              services: this.services,
              range: this.range,
            })
            .then((response) => {
              if (response.data.data) {
                this.$modal.hide("tracker-delete-data-form")
                this.$message.success(response.data.message)
              } else {
                this.$message.warning(response.data.message)
              }
              this.loading = false
            })
            .catch((resp) => {
              this.loading = false
              if (resp.response && resp.response.status === 422) {
                this.form.onFail(resp)
              } else {
                this.$root.globalRequestError(resp)
              }
            })
        })
        .catch(() => {})
    },
  },

  created() {},
  mounted() {},
}
</script>
