<template>
  <el-card v-if="data">
    <el-row
      style="display: flex; justify-content: flex-end; margin-bottom: 0.5rem"
    >
      <el-button
        size="small"
        :type="data.paused ? 'success' : 'warning'"
        @click="pauseTracker"
      >
        <font-awesome-icon
          :icon="data.paused ? 'play' : 'pause'"
          fixed-width
          style="margin-right: 0.5rem"
        ></font-awesome-icon>
        {{ data.paused ? "Activate" : "Pause" }} Tracker
      </el-button>
      <el-button size="small" type="danger" @click="openTrackerDeleteDataModal"
        >Delete Data</el-button
      >
      <el-button size="small" type="default" @click="openTrackerEditModal"
        >Modify</el-button
      >
    </el-row>

    <el-row :gutter="20">
      <el-col class="service-list" :md="16">
        <div
          class="tracker-settings-service"
          :class="{
            inactive:
              availableServices.indexOf('google-display') === -1 &&
              availableServices.indexOf('facebook-display') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="
                'google-display' in data.services ||
                'facebook-display' in data.services
              "
              @click="openServiceEditModal('display')"
            ></font-awesome-icon>
          </div>
          <div class="status">&nbsp;</div>
          <div class="wrapper">
            <div class="service">Display</div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('google-display') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'google-display' in data.services"
              @click="openServiceEditModal('google-display')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('google-display') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('google-display')"
                class="service-logo"
              />
              Google
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('google-display') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["google-display"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["google-display"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('facebook-display') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'facebook-display' in data.services"
              @click="openServiceEditModal('facebook-display')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('facebook-display') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('facebook-display')"
                class="service-logo"
              />
              Facebook
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('facebook-display') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["facebook-display"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["facebook-display"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>


        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('agility-display') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'agility-display' in data.services"
              @click="openServiceEditModal('agility-display')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('agility-display') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('agility-display')"
                class="service-logo"
              />
              Agility Ads
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('agility-display') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["agility-display"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["agility-display"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('simplifi-display') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'simplifi-display' in data.services"
              @click="openServiceEditModal('simplifi-display')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('simplifi-display') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('simplifi-display')"
                class="service-logo"
              />
              Simpli.fi
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('simplifi-display') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["simplifi-display"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["simplifi-display"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive:
              availableServices.indexOf('google-sem') === -1 &&
              availableServices.indexOf('bing-sem') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="
                'google-sem' in data.services || 'bing-sem' in data.services
              "
              @click="openServiceEditModal('sem')"
            ></font-awesome-icon>
          </div>
          <div class="status">&nbsp;</div>
          <div class="wrapper">
            <div class="service">SEM</div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{ inactive: availableServices.indexOf('google-sem') === -1 }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'google-sem' in data.services"
              @click="openServiceEditModal('google-sem')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('google-sem') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('google-sem')"
                class="service-logo"
              />
              Google
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('google-sem') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["google-sem"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(data.services["google-sem"].end, "M/D/YY h a")
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{ inactive: availableServices.indexOf('bing-sem') === -1 }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'bing-sem' in data.services"
              @click="openServiceEditModal('bing-sem')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('bing-sem') !== -1 ? 'check' : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('bing-sem')"
                class="service-logo"
              />
              Bing
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('bing-sem') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(data.services["bing-sem"].start, "M/D/YY h a")
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(data.services["bing-sem"].end, "M/D/YY h a")
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive:
              availableServices.indexOf('siteimpact-mail') === -1 &&
              availableServices.indexOf('mailchimp-mail') === -1 &&
              availableServices.indexOf('emailresultz-mail') === -1 &&
              availableServices.indexOf('constantcontact-mail') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="
                'siteimpact-mail' in data.services ||
                'mailchimp-mail' in data.services
              "
              @click="openServiceEditModal('mail')"
            ></font-awesome-icon>
          </div>
          <div class="status">&nbsp;</div>
          <div class="wrapper">
            <div class="service">Mail</div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('siteimpact-mail') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'siteimpact-mail' in data.services"
              @click="openServiceEditModal('siteimpact-mail')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('siteimpact-mail') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('siteimpact-mail')"
                class="service-logo"
              />
              Siteimpact
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('siteimpact-mail') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["siteimpact-mail"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["siteimpact-mail"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('mailchimp-mail') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'mailchimp-mail' in data.services"
              @click="openServiceEditModal('mailchimp-mail')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('mailchimp-mail') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('mailchimp-mail')"
                class="service-logo"
              />
              MailChimp
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('mailchimp-mail') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["mailchimp-mail"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["mailchimp-mail"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('emailresultz-mail') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'emailresultz-mail' in data.services"
              @click="openServiceEditModal('emailresultz-mail')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('emailresultz-mail') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('emailresultz-mail')"
                class="service-logo"
              />
              Email Resultz
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('emailresultz-mail') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["emailresultz-mail"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["emailresultz-mail"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('constantcontact-mail') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'constantcontact-mail' in data.services"
              @click="openServiceEditModal('constantcontact-mail')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('constantcontact-mail') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('constantcontact-mail')"
                class="service-logo"
              />
              Constant Contact
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('constantcontact-mail') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["constantcontact-mail"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["constantcontact-mail"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive:
              availableServices.indexOf('simplifi-tv') === -1 ||
              availableServices.indexOf('tremor-tv') === -1 ||
              availableServices.indexOf('settop-tv') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="
                'simplifi-tv' in data.services ||
                'tremor-tv' in data.services ||
                'settop-tv' in data.services
              "
              @click="openServiceEditModal('tv')"
            ></font-awesome-icon>
          </div>
          <div class="status">&nbsp;</div>
          <div class="wrapper">
            <div class="service">TV</div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{ inactive: availableServices.indexOf('simplifi-tv') === -1 }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'simplifi-tv' in data.services"
              @click="openServiceEditModal('simplifi-tv')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('simplifi-tv') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('simplifi-tv')"
                class="service-logo"
              />
              Simplifi
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('simplifi-tv') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["simplifi-tv"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(data.services["simplifi-tv"].end, "M/D/YY h a")
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{ inactive: availableServices.indexOf('agility-tv') === -1 }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'agility-tv' in data.services"
              @click="openServiceEditModal('agility-tv')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('agility-tv') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('agility-tv')"
                class="service-logo"
              />
              Agility Ads
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('agility-tv') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["agility-tv"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(data.services["agility-tv"].end, "M/D/YY h a")
                }}
              </div>
            </div>
          </div>
        </div>


        <div
          class="tracker-settings-service"
          :class="{ inactive: availableServices.indexOf('tremor-tv') === -1 }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'tremor-tv' in data.services"
              @click="openServiceEditModal('tremor-tv')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('tremor-tv') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('tremor-tv')"
                class="service-logo"
              />
              Tremor
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('tremor-tv') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(data.services["tremor-tv"].start, "M/D/YY h a")
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(data.services["tremor-tv"].end, "M/D/YY h a")
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{ inactive: availableServices.indexOf('settop-tv') === -1 }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'settop-tv' in data.services"
              @click="openServiceEditModal('settop-tv')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('settop-tv') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('settop-tv')"
                class="service-logo"
              />
              Set Top
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('settop-tv') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(data.services["settop-tv"].start, "M/D/YY h a")
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(data.services["settop-tv"].end, "M/D/YY h a")
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive:
              availableServices.indexOf('google-video') === -1 ||
              availableServices.indexOf('facebook-video') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="
                'google-video' in data.services ||
                'facebook-video' in data.services
              "
              @click="openServiceEditModal('video')"
            ></font-awesome-icon>
          </div>
          <div class="status">&nbsp;</div>
          <div class="wrapper">
            <div class="service">Video</div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('google-video') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'google-video' in data.services"
              @click="openServiceEditModal('google-video')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('google-video') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('google-video')"
                class="service-logo"
              />
              Google
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('google-video') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["google-video"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["google-video"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="tracker-settings-service"
          :class="{
            inactive: availableServices.indexOf('facebook-video') === -1,
          }"
        >
          <div class="modify">
            <font-awesome-icon
              icon="pencil-alt"
              fixed-width
              class="modify-service-icon"
              v-if="'facebook-video' in data.services"
              @click="openServiceEditModal('facebook-video')"
            ></font-awesome-icon>
          </div>
          <div class="status">
            <font-awesome-icon
              class="status-icon"
              :icon="
                availableServices.indexOf('facebook-video') !== -1
                  ? 'check'
                  : 'times'
              "
              fixed-width
            ></font-awesome-icon>
          </div>
          <div class="wrapper">
            <div class="service">
              <img
                :src="getServiceLogo('facebook-video')"
                class="service-logo"
              />
              Facebook
            </div>
            <div
              class="date-range"
              v-if="availableServices.indexOf('facebook-video') !== -1"
            >
              <div class="start">
                {{
                  dateTimeFormat(
                    data.services["facebook-video"].start,
                    "M/D/YY h a"
                  )
                }}
              </div>
              <div class="to">to</div>
              <div class="end">
                {{
                  dateTimeFormat(
                    data.services["facebook-video"].end,
                    "M/D/YY h a"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :md="8">
        <div
          class="tracker-setting-option"
          :class="{ inactive: !data.remarketing }"
        >
          <font-awesome-icon
            :icon="data.remarketing ? 'check' : 'times'"
            fixed-width
          ></font-awesome-icon
          >Separate Remarketing
        </div>
        <div
          class="tracker-setting-option"
          :class="{ inactive: !data.conversions }"
        >
          <font-awesome-icon
            :icon="data.conversions ? 'check' : 'times'"
            fixed-width
          ></font-awesome-icon
          >Display Conversions
        </div>
        <div
          class="tracker-setting-option conversion-label"
          v-if="data.conversions_label"
        >
          Labeled as "{{ data.conversions_label }}" ({{
            data.conversions_abbreviation
          }}.)
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss">
</style>

<script>
import { mapActions } from "vuex"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"
import TrackerEditForm from "@/components/Forms/TrackerEditForm"
import TrackerEditServiceForm from "@/components/Forms/TrackerEditServiceForm"
import TrackerDeleteDataForm from "@/components/Forms/TrackerDeleteDataForm"
import DateTime from "@/mixins/DateTime"

export default {
  name: "TrackerCompanySettings",

  mixins: [TrackerServiceLogos, DateTime],

  props: {
    data: {
      required: true,
    },
    company: {
      required: true,
    },
  },
  data() {
    return {
      editing: {
        google_sheet: false,
        siteimpact: false,
      },
    }
  },
  computed: {
    availableServices() {
      if (!this.data) return
      if (typeof this.data.services !== "object") return []
      return Object.keys(this.data.services)
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    openTrackerEditModal() {
      this.$modal.show(
        TrackerEditForm,
        {
          existing: this.company,
        },
        {
          name: "tracker-edit-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    openTrackerDeleteDataModal() {
      this.$modal.show(
        TrackerDeleteDataForm,
        {
          existing: this.company,
        },
        {
          name: "tracker-delete-data-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    openServiceEditModal(service) {
      this.$modal.show(
        TrackerEditServiceForm,
        {
          existing: this.data.services[service],
          type: service,
        },
        {
          name: "tracker-edit-service-data-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    pauseTracker() {
      this.axios
        .post("tracker/" + this.$route.params.company + "/pause", [])
        .then((response) => {
          this.updateCompany(response.data.data)
          this.$message.success(response.data.message)
        })
        .catch((resp) => {
          this.$root.globalRequestError(resp)
        })
    },
  },

  created() {},
  mounted() {},
}
</script>
