<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>MailChimp Integration Settings</span>
      <el-button type="text" @click="$modal.hide('tracker-mailchimp-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('key') ? form.errors.first('key') : null"
        label="API Key"
        required
      >
        <el-input
          v-model="data.key"
          type="text"
          placeholder="MailChimp API Key"
          @focus="form.errors.clear('key')"
          :disabled="loading || currentStep === 2"
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('campaign') ? form.errors.first('campaign') : null
        "
        label="Folders"
        v-show="currentStep === 2"
      >
        <modal-select>
          <el-select
            v-model="data.folders"
            :style="{ width: '100%' }"
            clearable
            placeholder="Choose Campaign Folders or Leave Blank to Use All..."
            @focus="form.errors.clear('folders')"
            multiple
          >
            <el-option
              v-for="item in folders"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </modal-select>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-if="currentStep === 1"
        v-loading="loading"
        :disabled="loading"
      >
        Continue
      </el-button>

      <el-button-group v-loading="loading" :style="{ width: '100%' }" v-else>
        <el-button plain medium :style="{ width: '50%' }"> Back </el-button>
        <el-button
          type="primary"
          plain
          medium
          native-type="submit"
          :style="{ width: '50%' }"
        >
          Submit
        </el-button>
      </el-button-group>
    </el-form>
  </el-card>
</template>

<style scoped>
</style>

<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "TrackerMailChimpForm",

  mixins: [],
  components: {},

  props: {
    existing: {
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1,
      data: {
        key: "",
        folders: [],
      },
      folders: [],
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "companies/current",
    }),
    action() {
      return "tracker/" + this.company.slug + "/mailchimp/update"
    },
  },
  watch: {
    "data.key": {
      handler(newVal) {
        if (this.existing !== null) {
          if (newVal !== this.existing.key) {
            this.data.folders = []
          } else {
            this.data.folders = this.$_.map(this.existing.folders, "id")
          }
        }
      },
    },
  },

  methods: {
    ...mapActions({
      updateCompany: "tracker/updateAdmin",
    }),
    submit() {
      if (this.currentStep === 1) {
        this.loading = true
        this.axios
          .post("tracker/mailchimp/folders", { key: this.data.key })
          .then((response) => {
            this.folders = response.data.data
            this.currentStep = 2
            this.loading = false
          })
          .catch((resp) => {
            this.loading = false
            if (resp.response && resp.response.status === 422) {
              this.form.onFail(resp)
            } else {
              this.$root.globalRequestError(resp)
            }
          })
      } else if (this.currentStep === 2) {
        this.loading = true

        let formData = {
          key: this.data.key,
          available_folders: this.folders,
        }

        if (this.data.folders.length) {
          formData.folders = this.data.folders
        }

        this.axios
          .post(this.action, formData)
          .then((response) => {
            this.updateCompany(response.data.data)
            this.$message.success(response.data.message)
            this.$modal.hide("tracker-mailchimp-form")
            this.loading = false
          })
          .catch((resp) => {
            this.loading = false
            if (resp.response && resp.response.status === 422) {
              this.form.onFail(resp)
            } else {
              this.$root.globalRequestError(resp)
            }
          })
      }
    },
  },

  created() {
    if (this.existing !== null) {
      this.data.key = this.existing.key
    }
  },
  mounted() {},
}
</script>
